import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';

import {BrowserRouter as Router} from 'react-router-dom';
import {UseRequestProvider} from 'ahooks';

import Header from './components/Header';
import Footer from './components/Footer';
import Loading from './components/Loading';

import UserContext from './UserContext';
import SwitchRoute from './SwitchRoute';

function App() {
  const [state, setState] = useState(true);
  const [user, setUser] = useState({
    token: window.localStorage.getItem('token') || undefined,
  });
  const [priceInfo, setPriceInfo] = useState({});
  const userInfoRequest = useRequest(
    body => ({
      url: '/blockBrowser/user/userInfo/userInfo',
      method: 'post',
      headers: {
        Authorization: user.token,
      },
      body: JSON.stringify(body),
    }),
    {manual: true},
  );
  // const priceInfoRequest = useRequest(
  //   body => ({
  //     url: '/blockBrowser/common/priceInfo',
  //     method: 'post',
  //     body: JSON.stringify(body),
  //   }),
  //   {manual: true},
  // );
  const priceInfoRequest = {
    run: () => {
      return Promise.resolve({
        data: {
          ht_Info: {
            ht_usd: {
              close_price: '0',
              open_price: '0',
            },
            ht_btc: {
              close_price: '0',
              open_price: '0',
              exchange_volume: '0',
            },
          },
          total_supply: '0',
        },
      });
    },
  };

  useEffect(() => {
    priceInfoRequest.run().then(res => {
      setPriceInfo(res.data);

      if (user.token) {
        userInfoRequest.run().then(res => {
          if (res.code === 0) {
            setUser({...user, ...res.data});
          }
          setState(true);
        });
      } else {
        setState(true);
      }
    });
  }, []);

  if (priceInfoRequest.loading) {
    return <Loading />;
  }

  return (
    <UseRequestProvider value={{formatResult: r => r.data}}>
      <UserContext.Provider
        value={{
          user: user,
          priceInfo: priceInfo,
          updateUser: e => {
            setUser({...user, ...e});
          },
        }}
      >
        <Router>
          <Header />
          {state ? <SwitchRoute /> : undefined}
          <Footer />
        </Router>
      </UserContext.Provider>
    </UseRequestProvider>
  );
}

export default App;
