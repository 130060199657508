import { useState, useRef, useEffect, useContext } from 'react';
import { useRequest } from 'ahooks';
import moment from 'moment';

export default function Charts() {
  const body = {
    start_date: '',
    end_date: '',
    // start_date: moment().subtract(1, 'days').subtract(3, "months").format('YYYY-MM-DD'),
    // end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
  };
  // 图表一
  const dailyTradeChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/tradeChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const dailyChartData = dailyTradeChartRequest.data || [];

  const DTChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data.chart_list) && data.chart_list.length) {
        var litChartData = data.chart_list.map(d => {
          return {
            y: Number(d.trade_amount),
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
          },
          title: false,
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  // 图表二
  const tokenTradeChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/tokenTradeChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const tokenChartData = tokenTradeChartRequest.data || [];

  const TokenChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data) && data.length) {
        var litChartData = data.map(d => {
          return {
            y: Number(d.trade_amount),
            // formattedValue: d.price,
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        //$('#containerchart').highcharts({
        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
            // zoomType: 'x',
            // resetZoomButton: {
            //   position: {
            //     align: 'left', // right by default
            //     verticalAlign: 'top',
            //     //x: -55,
            //     //y: 10
            //     x: 10,
            //     y: 10,
            //   },
            //   relativeTo: 'plot',
            // },
          },
          title: false,
          // subtitle: {
          //   text: document.ontouchstart === undefined ? 'Source: caduceusinfo.com<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
          // },
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  // 图表三
  const uniqueAddrChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/uniqueAddrChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const uAddrChartData = uniqueAddrChartRequest.data || [];

  const UAddrChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data.chart_list) && data.chart_list.length) {
        var litChartData = data.chart_list.map(d => {
          return {
            y: Number(d.total_addresses),
            // formattedValue: d.price,
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
            // zoomType: 'x',
            // resetZoomButton: {
            //   position: {
            //     align: 'left', // right by default
            //     verticalAlign: 'top',
            //     //x: -55,
            //     //y: 10
            //     x: 10,
            //     y: 10,
            //   },
            //   relativeTo: 'plot',
            // },
          },
          title: false,
          // subtitle: {
          //   text: document.ontouchstart === undefined ? 'Source: caduceusinfo.com<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
          // },
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  // 图表四
  const avgBlockSizeChartRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/avgBlockSizeChart',
    method: 'post',
    body: JSON.stringify(body),
  });

  const BSizeChartData = avgBlockSizeChartRequest.data || [];

  const BSizeChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data) && data.length) {
        var litChartData = data.map(d => {
          return {
            y: Number(d.avg_block_size),
            // formattedValue: d.price,
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        //$('#containerchart').highcharts({
        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
            // zoomType: 'x',
            // resetZoomButton: {
            //   position: {
            //     align: 'left', // right by default
            //     verticalAlign: 'top',
            //     //x: -55,
            //     //y: 10
            //     x: 10,
            //     y: 10,
            //   },
            //   relativeTo: 'plot',
            // },
          },
          title: false,
          // subtitle: {
          //   text: document.ontouchstart === undefined ? 'Source: caduceusinfo.com<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
          // },
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  // 图表五
  const activeAdsRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/activeAddresses',
    method: 'post',
    body: JSON.stringify(body),
  });

  const activeAdsChartData = activeAdsRequest.data || [];

  const ActiveAdsChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data.chart_list) && data.chart_list.length) {
        var litChartData = data.chart_list.map(d => {
          return {
            y: Number(d.receive_count),
            // formattedValue: d.price,
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
            // zoomType: 'x',
            // resetZoomButton: {
            //   position: {
            //     align: 'left', // right by default
            //     verticalAlign: 'top',
            //     //x: -55,
            //     //y: 10
            //     x: 10,
            //     y: 10,
            //   },
            //   relativeTo: 'plot',
            // },
          },
          title: false,
          // subtitle: {
          //   text: document.ontouchstart === undefined ? 'Source: caduceusinfo.com<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
          // },
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  // 图标六
  const crcAdsRequest = useRequest({
    url: '/blockBrowser/resources/chartStats/blockChainData/activeCRC20Addresses',
    method: 'post',
    body: JSON.stringify(body),
  });
  const crcAdsChartData = crcAdsRequest.data || [];

  const CrcAdsChart = ({ data = [] }) => {
    const containerRef = useRef();
    useEffect(() => {
      if (Array.isArray(data.chart_list) && data.chart_list.length) {
        var litChartData = data.chart_list.map(d => {
          return {
            y: Number(d.token_total_addr_count),
            // formattedValue: d.price,
            dt: moment(d.date).format('dddd, MMMM D, YYYY'),
          };
        });

        window.Highcharts.setOptions({
          lang: {
            numericSymbols: [' k', ' M', ' B', ' T', ' P', ' E'],
          },
        });

        var chart = new window.Highcharts.chart({
          chart: {
            renderTo: containerRef.current,
            // zoomType: 'x',
            // resetZoomButton: {
            //   position: {
            //     align: 'left', // right by default
            //     verticalAlign: 'top',
            //     //x: -55,
            //     //y: 10
            //     x: 10,
            //     y: 10,
            //   },
            //   relativeTo: 'plot',
            // },
          },
          title: false,
          // subtitle: {
          //   text: document.ontouchstart === undefined ? 'Source: caduceusinfo.com<br>Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in',
          // },
          xAxis: {
            type: 'datetime',
            minRange: 14 * 24 * 3600000,
          },
          yAxis: {
            title: false,
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            area: {
              fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, window.Highcharts.getOptions().colors[0]],
                  [1, window.Highcharts.Color(window.Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')],
                ],
              },
              marker: {
                radius: 2,
              },
              lineWidth: 1,
              states: {
                hover: {
                  lineWidth: 1,
                },
              },
              threshold: null,
            },
          },
          credits: {
            enabled: false,
          },

          tooltip: false,
          series: [
            {
              type: 'area',
              turboThreshold: 10000,
              name: 'Daily Transaction Count',
              pointInterval: 24 * 3600 * 1000,
              pointStart: Date.UTC(2020, 11, 1),
              data: litChartData,
            },
          ],
        });

        function generateAnnotationText(title, desc, url) {
          var text = '';

          if (title) {
            text +=
              '<tr><td colspan=2 style="padding-bottom:5px;border-bottom:1px solid #D5D8DC;"><b><span style="font-size: 13px; font-weight: 900; font-family: arial;">' +
              title +
              '</span></b><br></td></tr>';
          }

          if (desc) {
            text += '<tr><td style="padding-top:6px;padding-bottom:2px;" valign="top">' + desc;
            if (url) {
              text += ' ' + '<a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a>';
            }

            text += '</td></tr>';
          } else if (url) {
            text +=
              '<br><div class="d-flex justify-content-end"><a href="' + url + '" target="_blank" rel="nofollow" class="button" style="color:black"><i class="fa fa-external-link-alt"></i></a></div>';
          }

          return text;
        }

        function getAnnotations() {
          var dataArray = [];
          //var chartdata = chart.series[0].data;
          var i, dataSubArray, add_Location, xCoordinate, yCoordinate;
          for (i = 0; i < dataArray.length; ++i) {
            dataSubArray = dataArray[i];
            add_Location = (i % 3) * 30;
            chart.addAnnotation({
              id: dataSubArray['pointid'],
              labels: [
                {
                  useHTML: true,
                  point: {
                    x: dataSubArray['x'],
                    y: dataSubArray['y'],
                    xAxis: dataSubArray['xaxis'],
                    yAxis: dataSubArray['yaxis'],
                  },
                  text: generateAnnotationText(dataSubArray['title'], dataSubArray['desc'], dataSubArray['url']),
                  x: -100,
                  style: {
                    color: 'rgb(0,0,0)',
                  },
                },
              ],
              labelOptions: {
                borderRadius: 5,
                backgroundColor: 'rgba(255, 255, 255)',
                borderWidth: 1,
                borderColor: '#ddd',
                y: 0,
              },
            });
          }
        }

        getAnnotations();
      }
    }, [data]);

    return <div className="content" ref={containerRef} style={{
      height: '200px', minWidth: '210px', overflow: 'hidden',
    }} data-highcharts-chart="0"></div>;
  }

  return (
    <main id="content" role="main">
      <div className="container">
        <div className="d-md-flex justify-content-between align-items-center py-3">
          <div className="mb-1 mb-md-0">
            <h1 className="h4 mb-0">Caduceus Chain Charts &amp; Statistics</h1>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb small p-0 mb-0">
              <li className="breadcrumb-item active">Charts &amp; Stats</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container space-bottom-2">
        <div className="card mb-4" id="blockchainData">
          <div className="card-header">
            <h2 className="card-header-title">Blockchain Data</h2>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/tx">Daily Transactions Chart</a>
                  </div>
                  <a className="card-body" href="/chart/tx">
                    <DTChart data={dailyChartData} />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/token-transfer">CRC-20 Daily Token Transfer Chart</a>
                  </div>
                  <a className="card-body" href="/chart/token-transfer">
                    <TokenChart data={tokenChartData} />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/unique-address">Unique Addresses Chart</a>
                  </div>
                  <a className="card-body" href="/chart/unique-address">
                    <UAddrChart data={uAddrChartData} />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/blocksize">Average Block Size Chart</a>
                  </div>
                  <a className="card-body" href="/chart/blocksize">
                    <BSizeChart data={BSizeChartData} />
                  </a>
                </div>
              </div>
              {/* <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/blocktime">Average Block Time Chart</a>
                  </div>
                  <a className="card-body" href="/chart/blocktime">
                    <img className="img-fluid w-100" src="/images/charts/blocktime.svg?v=0.0.5" alt="Average Block Time Chart" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/gasprice">Average Gas Price Chart</a>
                  </div>
                  <a className="card-body" href="/chart/gasprice">
                    <img className="img-fluid w-100" src="/images/charts/gasprice.svg?v=0.0.5" alt="Average Gas Price Chart" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/gaslimit">Average Gas Limit Chart</a>
                  </div>
                  <a className="card-body" href="/chart/gaslimit">
                    <img className="img-fluid w-100" src="/images/charts/gaslimit.svg?v=0.0.5" alt="Average Gas Limit Chart" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/gasused">Daily Gas Used Chart</a>
                  </div>
                  <a className="card-body" href="/chart/gasused">
                    <img className="img-fluid w-100" src="/images/charts/gasused.svg?v=0.0.5" alt="Daily Gas Used Chart" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/blocks">Block Count and Rewards Chart</a>
                  </div>
                  <a className="card-body" href="/chart/blocks">
                    <img className="img-fluid w-100" src="/images/charts/blockcount.svg?v=0.0.5" alt="Block Count and Rewards Chart" />
                  </a>
                </div>
              </div> */}
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/active-address">Daily Active Caduceus Chain Address</a>
                  </div>
                  <a className="card-body" href="/chart/active-address">
                    <ActiveAdsChart data={activeAdsChartData} />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-lg-3 mb-3">
                <div className="card h-100 w-100">
                  <div className="card-header bg-light">
                    <a href="/chart/crc20-active-address">Daily Active CRC-20 Address</a>
                  </div>
                  <a className="card-body" href='/chart/crc20-active-address' >
                    <CrcAdsChart data={crcAdsChartData} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div id="share-bottom" className="share-btn socialmediabutton-down" style={{ position: 'relative', right: '2px', top: '-10px' }}></div>
    </main>
  );
}
