import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';
import Loading from '../../components/Loading';

export default function TokenInfo({a, token, overview}) {
  const [state] = useState({
    body: {
      field: a ? 'address' : undefined,
      value: a,
      token_address: token,
      token_types: overview.token_types,
    },
  });

  const tokenInfoRequest = useRequest(
    body => ({
      url: '/blockBrowser/tokens/address/tokenInfo',
      method: 'post',
      body: JSON.stringify(body),
    }),
    {manual: true},
  );

  useEffect(() => {
    tokenInfoRequest.run(state.body);
  }, []);

  if (tokenInfoRequest.loading) {
    return <Loading />;
  }

  const data = tokenInfoRequest.data?.overview || [];

  if (a) {
    return (
      <div className="table-responsive mb-2" style={{overflow: 'auto', WebkitOverflowScrolling: 'touch'}}>
        <p>
          <ins>
            <strong>Market</strong>
          </ins>
        </p>
        <table border="0" cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td style={{width: '150px'}}>Volume (24H)</td>
              <td style={{width: '15px'}}>:</td>
              <td>$0.00</td>
            </tr>
            <tr>
              <td style={{width: '150px'}}>Market Capitalization</td>
              <td style={{width: '15px'}}>:</td>
              <td>$0.00</td>
            </tr>
            <tr>
              <td style={{width: '150px'}}>Circulating Supply</td>
              <td style={{width: '15px'}}>:</td>
              <td>0.00 HOT</td>
            </tr>
            <tr>
              <td colSpan="3">
                <span className="small">
                  Market Data Source:{' '}
                  <a href="https://coinmarketcap.com/" target="_blank">
                    Coinmarketcap
                  </a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <span className="small">
          Update? Click here to <a href="/contactus"> update</a> the token ICO / general information
        </span>
        <br />
      </div>
    );
  }

  return (
    <div className="table-responsive mb-2" style={{overflow: 'auto', WebkitOverflowScrolling: 'touch'}}>
      <p>
        <strong>OVERVIEW</strong>
      </p>
      Ethereum is a global, open-source platform for decentralized applications. Eth is fueling transactions on the Chain
      <br />
      <br />
      <br />
      <span className="small">
        Update? Click here to <a href="/contactus"> update</a> the token ICO / general information
      </span>
      <br />
    </div>
  );
}
