import {useEffect, useState} from 'react';
import {useRequest} from 'ahooks';

import StepOne from './StepOne';
import StepTwo from './StepTwo';

export default function VerifyContract() {
  const [step, setStep] = useState(1);
  const [state, setState] = useState({});

  const compilerLicenseListRequest = useRequest(body => ({
    url: '/blockBrowser/contract/compilerLicenseList',
    method: 'GET',
  }));

  const compilerVersionListRequest = useRequest(body => ({
    url: '/blockBrowser/contract/compilerVersionList',
    method: 'GET',
  }));

  const evmVersionListRequest = useRequest(body => ({
    url: '/blockBrowser/contract/evmVersionList',
    method: 'GET',
  }));

  const handleConfirmStepOne = e => {
    setState({...state, ...e});
    setStep(2);
  };

  const compilerLicenseList = compilerLicenseListRequest.data || [];
  const compilerVersionList = compilerVersionListRequest.data || [];
  const evmVersionList = evmVersionListRequest.data || [];
  console.log('evmVersionList', evmVersionList);
  if (step === 1) {
    return <StepOne compilerLicenseList={compilerLicenseList} compilerVersionList={compilerVersionList} onConfirm={handleConfirmStepOne} />;
  } else if (step === 2) {
    return (
      <StepTwo
        stepOneData={state}
        evmVersionList={evmVersionList}
        compilerLicenseList={compilerLicenseList}
        compilerVersionList={compilerVersionList}
        onBack={() => {
          setStep(1);
        }}
      />
    );
  }
}
