export default function Loading() {
  return (
    <div style={{position: 'relative', minHeight: '160px'}}>
      <div style={{position: 'absolute', left: '50%', marginLeft: '-90px'}}>
        <div className="py-3 text-center">
          <img src="/images/main/loadingblock.gif" alt="Loading" style={{heigt: '180px', width: '180px'}} />
        </div>
      </div>
    </div>
  );
}
