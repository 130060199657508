import {useLocation} from 'react-router-dom';
import qs from 'qs';

export default function Search() {
  const location = useLocation();
  const query = qs.parse(location.search, {ignoreQueryPrefix: true});
  return (
    <main role="main" style={{backgroundImage: 'url(images/error-404.svg)', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
      <div className="container d-lg-flex align-items-lg-center min-height-550 space-1">
        <div className="w-lg-60 w-xl-50">
          <div className="mb-5">
            <h1 className="text-primary font-weight-normal">
              Search not <span className="font-weight-semi-bold">found</span>
            </h1>
            <p className="mb-0">
              Oops! The search string you entered was: <b>{query.q}</b>
            </p>
            <p>Sorry! This is an invalid search string.</p>
            <p></p>
            <p>
              If you think this is a problem with us, please <a href="/contactus">tell us</a>.
            </p>
          </div>

          <a className="btn btn-primary btn-wide transition-3d-hover" href="/">
            Back Home
          </a>
        </div>
      </div>
    </main>
  );
}
