import { DatePicker} from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import './index.css'

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';
const disabledDate = (current) => {
    // 2022-04-28
    const specifyDate = current && current < moment('2022-04-28')
    // 今天之后的
    const endDay = current && current > dayjs().endOf('day')
    return specifyDate || endDay
}


export default function ChooseDate({ offlineData = [], title, timeType, selectType, onChange, bodyDate }) {
    return (
        <div className='head' >
            <div className='salesExtra'>
                <a className={timeType == 'daily' && 'currentDate'} onClick={() => selectType('daily')}>
                    Day
                </a>
                <a className={timeType == 'weekly' && 'currentDate'} onClick={() => selectType('weekly')}>
                    Week
                </a>
                <a className={timeType == 'monthly' && 'currentDate'} onClick={() => selectType('monthly')}>
                    Month
                </a>
            </div>
            <RangePicker
                // defaultValue={[dayjs(bodyDate.start_date, dateFormat), dayjs(bodyDate.end_date, dateFormat)]}
                disabled={timeType == 'weekly' || timeType == 'monthly'}
                disabledDate={disabledDate}
                onChange={onChange}
            />
        </div>
    )
}
