import { useLocation } from 'react-router-dom';
import qs from 'qs';

export default function Pager({ current, hash, total, path }) {
  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  let j = `${Number(current) - 1}${hash ? hash : ''}`
  let a = `${Number(current) + 1}${hash ? hash : ''}`
  let p = window.decodeURIComponent(`${path}${qs.stringify({ ...query, p: j }, { addQueryPrefix: true })}`)
  let n = window.decodeURIComponent(`${path}${qs.stringify({ ...query, p: a }, { addQueryPrefix: true })}`)
  const prevPage = p
  const nextPage = n;

  const search = location.search.lastIndexOf('=');
  const path1 = location.search.substring(0, search);
  const path2 = location.search.split('#')[0];
  const equal = '='

  return (
    <nav aria-label="page navigation">
      <ul className="pagination pagination-sm mb-0">
        {current === '1' ? (
          <li className="page-item disabled">
            <span className="page-link">First</span>
          </li>
        ) : (
          <li className="page-item">
            {/* <a className="page-link" href={`${path}?p=1`}> */}
            <a className="page-link" href={current !== '1' ? `${path}${path1}${equal}1${hash ? hash : ''}` : `${path}?p=1`}>
              <span aria-hidden="True">First</span> <span className="sr-only">First</span>
            </a>
          </li>
        )}

        {current === '1' ? (
          <li className="page-item disabled">
            <span className="page-link">
              <i className="fa fa-chevron-left small"></i>
            </span>
            <span className="sr-only">Previous</span>
          </li>
        ) : (
          <li className="page-item" data-toggle="tooltip" title="" data-original-title="Go to Previous">
            <a className="page-link" href={prevPage} aria-label="Previous" >
              <span aria-hidden="True">
                <i className="fa fa-chevron-left small"></i>
              </span>
              <span className="sr-only"> Previous</span>
            </a>
          </li>
        )}

        <li className="page-item disabled">
          <span className="page-link text-nowrap">
            Page <strong className="font-weight-medium">{current}</strong> of <strong className="font-weight-medium">{total || '1'}</strong>
          </span>
        </li>
        {current === String(total) || total === 0 ? (
          <li className="page-item disabled">
            <span className="page-link">
              <i className="fa fa-chevron-right small"></i>
            </span>
            <span className="sr-only"> Next</span>
          </li>
        ) : (
          <li className="page-item" data-toggle="tooltip" title="Go to Next">
            <a className="page-link" href={nextPage} aria-label="Next">
              <span aria-hidden="True">
                <i className="fa fa-chevron-right small"></i>
              </span>
              <span className="sr-only"> Next</span>
            </a>
          </li>
        )}
        {current === String(total) || total === 0 ? (
          <li className="page-item disabled">
            <span className="page-link">Last</span>
          </li>
        ) : (
          <li className="page-item">
            {/* <a className="page-link" href={`${path}?p=${total}`}> */}
            <a className="page-link"
              href={

                query.p === undefined ? `${path}${location.search}?p${equal}${total || '1'}${hash ? hash : ''}` : query.p !== String(total) ? `${path}${path1}${equal}${total || '1'}${hash ? hash : ''}` : `${path}?p=${total}${hash ? hash : ''}`
              }>
              <span aria-hidden="True">Last</span> <span className="sr-only">Last</span>
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
}
