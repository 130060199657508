import {useState} from 'react';

export default function Settings() {
  const defaultLNG = window.localStorage.getItem('lng') || 'en_US';
  const [lng, setLng] = useState(defaultLNG);

  const handleChange = e => {
    setLng(e.target.value);
  };

  const handleSave = e => {
    window.localStorage.setItem('lng', lng);
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };
  return (
    <main id="content" role="main">
      <div className="w-md-75 w-lg-50 text-center mx-md-auto space-top-2 pb-3">
        <h1 className="h3">Site Preferences</h1>
        <p>This page allows you to set up your browsing experience such as language, advanced view etc. on CaduceusInfo</p>
      </div>

      <div className="container space-bottom-2">
        <div className="w-md-75 w-lg-50 mx-md-auto">
          <div className="card mb-3">
            <div className="card-body p-4">
              <form method="post" action="./settings" id="ctl00">
                <div className="media align-items-center">
                  <label className="media-body text-secondary mb-0" htmlFor="checkboxSwitch">
                    <h3 className="h6 font-weight-bold mb-1">Advanced Mode</h3>
                    <span>Show transactions and blocks details in full. Useful for developers to debug in single view</span>
                  </label>
                  <label className="checkbox-switch ml-3 mb-0">
                    <input
                      type="checkbox"
                      className="checkbox-switch__input"
                      checked="checked"
                      onChange={e => {
                        console.log(e);
                      }}
                    />
                    <span className="checkbox-switch__slider"></span>
                  </label>
                </div>

                <hr />

                <div className="media align-items-center">
                  <label className="media-body text-secondary mb-0">
                    <h3 className="h6 font-weight-bold mb-1">Language</h3>
                    <span>Choose desired language, you can always update from your setting page</span>
                  </label>
                  <select onChange={handleChange} defaultValue={lng} className="custom-select custom-select-xs font-size-base ml-3 pb-1">
                    <option value="en_US">English</option>
                    <option value="zh_CN">中文 (CN)</option>
                  </select>
                </div>

                <hr />

                <div className="media align-items-center d-none">
                  <label className="media-body text-secondary mb-0">
                    <h3 className="h6 font-weight-bold mb-1">
                      Currency <span className="badge badge-success ml-1">New</span>
                    </h3>
                    <span>Choose desired currency</span>
                  </label>
                  <select className="custom-select custom-select-xs font-size-base ml-3 pb-1">
                    <option value="USD">United States Dollar</option>
                  </select>
                </div>

                <hr className="d-none" />
                <div className="text-right">
                  <input type="button" onClick={handleSave} value="Save Preferences" className="btn btn-xs btn-primary" />
                </div>
              </form>
            </div>
          </div>
          <p className="font-size-1">* Site preferences are saved as browser cookies.</p>
        </div>
      </div>
    </main>
  );
}
